import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 80px;
  grid-template-areas: ${(props) => (props.reversed ? "'image content'" : "'content image'")};
  align-items: center;
  padding: 120px 0;
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'image'
      'content';
    justify-content: center;
    grid-row-gap: 40px;
    text-align: center;
    padding: 60px 0;
    & img {
      margin: 0 auto;
    }
  }
  & div {
    grid-area: content;
    & h2 {
      margin-bottom: 40px;
      ${media.tablet} {
        margin-bottom: 32px;
      }
    }
    & p {
      font-size: 18px;
      line-height: 30px;
    }
    & a {
      margin-top: 14px;
    }
  }
  & img {
    grid-area: image;
  }
`;
